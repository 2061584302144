<!-- 课程资源 -->
<template>
  <div class='resource-container'>
    <div class="container">
      <h1>课程资源</h1>
      <img src="../../assets/images/detail.png" alt="">
    </div>
  </div>
</template>

<script>

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {

    };
  },
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
}
</script>
<style lang='less' scoped>
.resource-container {
  margin-bottom: 197px;
  text-align: center;
  img{
    margin-top: 100px;
  }
}
</style>